import { GetServerSidePropsContext } from 'next';
import { NextRouter } from 'next/router';

export type QueryItemType = string | string[] | undefined;

export enum QueryKeys {
  nfcId = 'nfcId',
  redirectUrl = 'redirectUrl',
  type = 'type',
}

export type QueryParams = Partial<Record<QueryKeys, QueryItemType>>;

export const generateQuery = (router: NextRouter) => {
  const params: QueryParams = router.query;

  const query: QueryParams = {};

  for (const key in QueryKeys) {
    if (params[key as QueryKeys]) {
      query[key as QueryKeys] = params[key as QueryKeys];
    } else if (key === QueryKeys.redirectUrl) {
      query[key as QueryKeys] = params[key as QueryKeys] || router.asPath;
    }
  }

  return query;
};

export const serverSideGenerateQuery = (context: GetServerSidePropsContext) => {
  let query = '';

  for (const key in QueryKeys) {
    if (context.query[key]) {
      query += `&${key}=${context.query[key]}`;
    } else if (key === QueryKeys.redirectUrl) {
      query += context.query[key]
        ? `&${key}=${context.query[key]}`
        : `&${key}=${context.resolvedUrl}`;
    }
  }

  return query;
};

export const convertLinkYoutubeToEmbedLink = (link: string) => {
  const videoId = link.split('v=')[1];
  if (!videoId) return '';
  return `https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1`;
};
